body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  text-align: center;
  background: linear-gradient(to right, #e96443, #904e95);
  color: #1d4350;
  padding: 30px;
  border-radius: 10px;
  margin: auto;
  margin-top: 30px;
  width: 65%;
  z-index: 1;
}

.title {
  color: white;
  margin-bottom: 35px;
  font-size: 2.5em;
}

body {
  background: black;
}

#city {
  background: linear-gradient(to right, #e96443, #904e95);;
  color: white;
  /* border: 1px solid black; */
  border-radius: 5px;
  text-align: center;
  padding: 8px;
  margin-bottom: 10px;
}

.cityName{
  color: white;
}

.span1{
  color: white;
}

